import React from 'react'
import PropTypes from 'prop-types'
import {
    Card, CardContent, Divider, Collapse, Grid
} from '@material-ui/core'
import JobHeader from './JobHeader'
import JobCommon from './JobCommon'
import JobNote from './JobNote'
import JobPrice from './JobPrice'
import JobAttachments from './JobAttachments'
import { makeStyles } from '@material-ui/styles'
import { ExpandMore } from '@material-ui/icons'
import { CustomerPayment } from '../../CustomerJobs/components/Completed/components';

const useStyles = makeStyles(theme => ({
    jobItem: {
        position: 'relative',
        marginTop: 16,
        border: '1px solid #dadada'
    },
    expandIcon: {
        position: 'absolute',
        top: 5,
        right: 5,
        transition: 'all 200ms ease-in',
        cursor: 'pointer'
    },
}));

const JobItem = ({ job, expand, onExpand, onAddAttachment, onUpdateStatus, style, status,isEnableTodayRoute,isFieldTechnician }) => {
    const classes = useStyles();
    return (
        <Card className={classes.jobItem} style={style}>
            <ExpandMore className={classes.expandIcon} onClick={onExpand}
                style={{ transform: expand ? 'rotate(180deg)' : 'rotate(0deg)' }} />
            <CardContent style={{ cursor: 'pointer', position: 'relative' }}>
                <JobHeader status={status} job={job} onUpdateStatus={onUpdateStatus} onExpand={onExpand} isEnableTodayRoute={isEnableTodayRoute} isFieldTechnician={isFieldTechnician}/>
            </CardContent>
            <Divider />
            <Collapse in={expand} timeout='auto' unmountOnExit>
                <CardContent>
                    {
                        isEnableTodayRoute != null && isEnableTodayRoute ?
                        <>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <JobCommon job={job} isEnableTodayRoute={isEnableTodayRoute} isFieldTechnician={isFieldTechnician}/>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <JobNote job={job} isEnableTodayRoute={isEnableTodayRoute}/>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <JobAttachments job={job} isEnableTodayRoute={isEnableTodayRoute} onAddAttachment={onAddAttachment} />
                                </Grid>
                                {!(job && job.hidePriceLineItemsFromFieldTechs && isFieldTechnician) && <Grid item xs={12} md={12}>
                                    <JobPrice job={job} isEnableTodayRoute={isEnableTodayRoute}/>
                                </Grid>
                                }
                                <Grid item xs={12} md={12}>
                                    <CustomerPayment id={job.id} jobId={job.jobId} data={job} />
                                </Grid>
                            </Grid>
                        </>
                        :
                        <>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <JobCommon job={job} isEnableTodayRoute={isEnableTodayRoute} isFieldTechnician={isFieldTechnician}/>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <JobNote job={job} isEnableTodayRoute={isEnableTodayRoute}/>
                                </Grid>
                                {!(job && job.hidePriceLineItemsFromFieldTechs && isFieldTechnician) && <Grid item xs={12} md={12}>
                                    <JobPrice job={job} isEnableTodayRoute={isEnableTodayRoute}/>
                                </Grid>
                                }
                            </Grid>
                        </>
                    }
                    
                </CardContent>
            </Collapse>
        </Card>
    )
}

JobItem.propTypes = {
    job: PropTypes.object,
    expand: PropTypes.bool,
    onExpand: PropTypes.func,
    onUpdateStatus: PropTypes.func,
    style: PropTypes.object
}

export default JobItem;
